@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Glegoo&family=Lora&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

/* Font - Bold */
@font-face {
  font-family: "Gilroy-Bold";
  src: local("Gilroy-Bold"),
    url("./assets/fonts/Gilroy-Bold.ttf") format("truetype");
  /* Add additional formats if needed */
}

/* Font - Bold Italic */
@font-face {
  font-family: "Gilroy-BoldItalic";
  src: local("Gilroy-BoldItalic"),
    url("./assets/fonts/Gilroy-BoldItalic.ttf") format("truetype");
}

/* Font - Extra Bold */
@font-face {
  font-family: "Gilroy-ExtraBold";
  src: local("Gilroy-ExtraBold"),
    url("./assets/fonts/Gilroy-ExtraBold.ttf") format("truetype");
}

/* Font - Extra Bold Italic */
@font-face {
  font-family: "Gilroy-ExtraBoldItalic";
  src: local("Gilroy-ExtraBoldItalic"),
    url("./assets/fonts/Gilroy-ExtraBoldItalic.ttf") format("truetype");
}

/* Font - Heavy */
@font-face {
  font-family: "Gilroy-Heavy";
  src: local("Gilroy-Heavy"),
    url("./assets/fonts/Gilroy-Heavy.ttf") format("truetype");
}

/* Font - Heavy Italic */
@font-face {
  font-family: "Gilroy-HeavyItalic";
  src: local("Gilroy-HeavyItalic"),
    url("./assets/fonts/Gilroy-HeavyItalic.ttf") format("truetype");
}

/* Font - Light Italic */
@font-face {
  font-family: "Gilroy-LightItalic";
  src: local("Gilroy-LightItalic"),
    url("./assets/fonts/Gilroy-LightItalic.ttf") format("truetype");
}

/* Font - Medium */
@font-face {
  font-family: "Gilroy-Medium";
  src: local("Gilroy-Medium"),
    url("./assets/fonts/Gilroy-Medium.ttf") format("truetype");
}

/* Font - Medium Italic */
@font-face {
  font-family: "Gilroy-MediumItalic";
  src: local("Gilroy-MediumItalic"),
    url("./assets/fonts/Gilroy-MediumItalic.ttf") format("truetype");
}

/* Font - Regular */
@font-face {
  font-family: "Gilroy-Regular";
  src: local("Gilroy-Regular"),
    url("./assets/fonts/Gilroy-Regular.ttf") format("truetype");
}

/* Font - Regular Italic */
@font-face {
  font-family: "Gilroy-RegularItalic";
  src: local("Gilroy-RegularItalic"),
    url("./assets/fonts/Gilroy-RegularItalic.ttf") format("truetype");
}

/* Font - Semi Bold */
@font-face {
  font-family: "Gilroy-SemiBold";
  src: local("Gilroy-SemiBold"),
    url("./assets/fonts/Gilroy-SemiBold.ttf") format("truetype");
}

/* Font - Semi Bold Italic */
@font-face {
  font-family: "Gilroy-SemiBoldItalic";
  src: local("Gilroy-SemiBoldItalic"),
    url("./assets/fonts/Gilroy-SemiBoldItalic.ttf") format("truetype");
}

/* Font - Thin */
@font-face {
  font-family: "Gilroy-Thin";
  src: local("Gilroy-Thin"),
    url("./assets/fonts/Gilroy-Thin.ttf") format("truetype");
}

/* Font - Thin Italic */
@font-face {
  font-family: "Gilroy-ThinItalic";
  src: local("Gilroy-ThinItalic"),
    url("./assets/fonts/Gilroy-ThinItalic.ttf") format("truetype");
}

/* Font - Ultra Light */
@font-face {
  font-family: "Gilroy-UltraLight";
  src: local("Gilroy-UltraLight"),
    url("./assets/fonts/Gilroy-UltraLight.ttf") format("truetype");
}

/* Font - Ultra Light Italic */
@font-face {
  font-family: "Gilroy-UltraLightItalic";
  src: local("Gilroy-UltraLightItalic"),
    url("./assets/fonts/Gilroy-UltraLightItalic.ttf") format("truetype");
}

/* Font - Ultra Light Italic */
@font-face {
  font-family: "Glegoo-Regular";
  src: local("Glegoo-Regular"),
    url("./assets/fonts/Glegoo-Regular.ttf") format("truetype");
}
/* Font - Ultra Light Italic */
@font-face {
  font-family: "Glegoo-Bold";
  src: local("Glegoo-Bold"),
    url("./assets/fonts/Glegoo-Bold.ttf") format("truetype");
}

.custom-scrollbar::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
  width: 0;
}



.scrollbar-container::-webkit-scrollbar {
  width: 0 !important;
  display: none !important; /* Hide scrollbar for Chrome, Safari, and Opera */
}

@layer utilities {
  .custom-sixt {
    @apply 2xl:text-[16px] xl:text-[14px] lg:text-[12px] text-[12px];
  }
  .custom-fourt {
    @apply 2xl:text-[14px] xl:text-[12px] lg:text-[10px] text-[10px];
  }
  .custom-login-text {
    @apply 2xl:text-[18px] xl:text-[16px] lg:text-[14px] text-[14px];
  }
}

body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Gilroy-Medium", sans-serif !important;
}
