$breakpoint-large: 1024px;
$breakpoint-xl: 1280px;
$breakpoint-2xl: 1536px;

$border-color: #919da9;

.ant-form-item {
  margin-bottom: 16px;
}

.ant-form-item-explain-error {
  margin: 5px 0;
}

.search-input {
  padding: 0.6rem 15px !important;
  border: 1px solid $border-color !important;
  background-color: transparent;
  outline: none;
  border-radius: 15px;
  box-shadow: none !important;

  .ant-input {
    padding: 0.2rem 0 !important;
    border: none !important;
  }

  &:focus,
  &:hover,
  &:focus-visible {
    border-color: $border-color !important;
    box-shadow: none !important;
  }
}

.ant-input {
  padding: 0.5rem 1rem !important;
  border-radius: 15px;
  border: 1px solid $border-color !important;
  background-color: transparent;
  outline: none;
  color: $primary !important;
  font-family: Gilroy-SemiBold;
  font-size: 16px !important;
  box-shadow: none !important;

  &:focus,
  &:hover,
  &:focus-visible {
    border-color: $border-color !important;
    box-shadow: none !important;
  }

  :placeholder-shown {
    font-size: 16px !important;
    // font-family: Gilroy-SemiBold !important;
  }
}
.ant-input-date {
  padding: 0px 0px 0px 8px;
  border-radius: 15px;
  border: 1px solid $border-color !important;
  background-color: white;
  outline: none;
  box-shadow: none !important;

  &:focus,
  &:hover,
  &:focus-visible {
    border-color: $border-color !important;
    box-shadow: none !important;
  }
  .ant-picker .ant-picker-input > input {
    color: #00205e;
    font-size: 16px;
  }
}
.ant-input-password {
  background-color: transparent;
  border: 1px solid $border-color !important;
  border-radius: 15px;

  .ant-input {
    border-radius: 15px;
    border: none !important;
    background-color: transparent;
    outline: none;
    box-shadow: none !important;
    padding: 0.3rem 5px !important;
  }

  &:focus,
  &:hover,
  &:focus-visible {
    border-color: $border-color !important;
    box-shadow: none !important;
  }
}

.ant-input-number {
  border: 1px solid $border-color !important;
  outline: none;
  border-radius: 15px;
  background-color: transparent;
  box-shadow: none !important;

  &:focus,
  &:hover,
  &:focus-visible {
    border-color: $border-color !important;
    box-shadow: none !important;
  }

  .ant-input-number-input {
    padding: 0.5rem 0.6rem;

    :placeholder-shown {
      font-size: 16px !important;
    }
  }
}

.ant-picker {
  border-radius: 15px;
  border-color: $border-color !important;
  outline: none !important;
  box-shadow: none !important;
  background-color: transparent !important;

  &:hover,
  &:focus,
  &:focus-visible {
    border-color: $border-color !important;
    box-shadow: none !important;
  }
  .ant-picker-input {
    // padding: 0.25rem 0.1rem;
    font-size: 16px !important;
    input {
      color: $primary !important;
      font-size: 16px !important;
      font-family: Gilroy-SemiBold !important;
    }
  }
}

.ant-select {
  height: auto !important;
  border: none !important;
  outline: none !important;
  color: $primary !important;
  font-family: Gilroy-SemiBold;
  font-size: 16px !important;
  background-color: transparent;
  box-shadow: none !important;

  .ant-select-selector {
    border-radius: 15px;
    padding: 0.35rem 0.6rem !important;
    background-color: transparent !important;
    box-shadow: none !important;
    font-size: 16px !important;
    outline: none;
    border-color: $border-color !important;
    color: $primary;
    .ant-select-selection-placeholder {
      font-size: 16px !important;
    }
  }

  &:hover,
  &:focus,
  &:focus-visible {
    .ant-select-selector {
      border-color: $border-color !important;
    }
  }
}

.ant-table {
  border-radius: 15px !important;

  .ant-table-row {
    cursor: pointer;
  }
}

.custom-table {
  background-color: transparent !important;
  th {
    background-color: transparent !important;
    color: rgba(145, 157, 169, 1) !important;
    font-size: 12px;
  }
  td:not(:last-child) {
    font-size: 14px;
    color: $primary;
    font-family: "Gilroy-Medium" sans-serif;
    border-bottom: 1px solid #919da94d !important;
    background-color: transparent !important;
  }
}

// MEDIA QUERY START

@media (min-width: $breakpoint-xl) {
  .ant-input {
    padding: 0.75rem 1rem !important;
  }
  .ant-input-date {
    padding: 0px 0px 0px 8px;
  }
  .ant-input-password {
    .ant-input {
      padding: 0.75rem 5px !important;
    }
  }
  .ant-input-number {
    .ant-input-number-input {
      padding: 0.75rem 1rem !important;
    }
  }

  .ant-select {
    .ant-select-selector {
      padding: 0.6rem 0.6rem !important;
    }
  }

  .ant-picker > .ant-picker-input {
    padding: 0.5rem 0rem;
  }
}

@media (min-width: $breakpoint-2xl) {
  .ant-input {
    padding: 1rem 1rem !important;
  }
  .ant-input-date {
    padding: 0px 0px 0px 8px;
  }
  .ant-input-number {
    .ant-input-number-input {
      padding: 1rem 1rem !important;
    }
  }

  .ant-select {
    .ant-select-selector {
      padding: 0.85rem 0.6rem !important;
    }
  }

  .ant-picker > .ant-picker-input {
    padding: 0.75rem 0rem;
  }
}

// MEDIA QUERY ENDS
.ant-menu-light
  .ant-menu-item:not(.ant-menu-item-selected):not(
    .ant-menu-submenu-selected
  ):hover {
  color: $primary;
}

// background color when value entered
// .ant-input-status-success{
//   background-color: white !important;
// }
// .ant-select-status-success{
//   background-color: white !important ;
// }
// .ant-picker-status-success {
//   background-color: white !important ;
// }
