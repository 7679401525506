#custom-switch {
  .ant-switch-inner {
    background-color: #ffded4;
    .ant-switch-inner-unchecked {
      color: $secondary;
    }
  }

  .ant-switch-handle {
    border: 2px solid $secondary;
    border-radius: 15px;
    padding: 4px;
  }

  .ant-switch-inner-checked {
    color: $primary;
  }

  .ant-switch-checked {
    background-color: #fff !important;

    .ant-switch-inner {
      border: 1px solid grey;
      color: $primary !important;
      background-color: #fff;
    }
    .ant-switch-handle {
      border: 0px solid $primary;
      border-radius: 15px;
      padding: 6px;
      background-color: $primary;

      &::before {
        background-color: $primary;
      }
    }
  }
}

#custom-calendar {
  .ant-picker-body {
    border: 1px solid #919da94d;
    /* border-radius: 15px; */
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
}

.without-border {
  border: none !important;
  &:focus-within {
    box-shadow: none !important;
  }

  .ant-input {
    border: none !important;
    padding: 0 !important;
    outline: none;
    box-shadow: none;

    &:hover,
    &:focus {
      border-color: transparent !important;
      outline: none;
      box-shadow: none !important;
    }
  }
}

.hr-calendar {
  .ant-picker-panel {
    // border-radius: 15px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .ant-picker-cell {
    // border-radius: 15px !important;

    .ant-picker-cell-inner {
      // border-radius: 15px;

      &::before {
        // border-radius: 15px !important;
        border: 1px solid $primary !important;
      }
    }
  }
  .ant-picker-cell-selected {
    .ant-picker-cell-inner {
      background-color: $primary !important;
    }

    .ant-picker-cell-inner {
      background-color: $primary !important;
    }
  }
}

// report css
.report-modal {
  background: url("../img/all/report-bg.png") no-repeat center center fixed !important;
  background-size: cover;

  .ant-table {
    ::-webkit-scrollbar {
      width: 8px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 15px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 15px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  .ant-table-wrapper .ant-table-thead > tr > th {
    background: white;
    font-size: 12px;
    color: #919da9;
    padding: 8px;
    text-align: center;
    border-bottom: 1px solid #919da9;
  }
  .ant-table-wrapper .ant-table-tbody > tr > td {
    padding: 8px;
    text-align: center;
    font-size: 14px;
    color: $primary;
  }
  .ant-table-wrapper
    .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-header
    > table
    > thead
    > tr
    > th {
    border-inline-end: 1px solid #919da9;
    border-top: 1px solid #919da9;
  }
  .ant-table-wrapper
    .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-body
    > table
    > tbody
    > tr
    > td {
    border-inline-end: 1px solid #919da9;
    // border-left: 1px solid #919da9;
  }
  .ant-table-wrapper .ant-table-tbody > tr > td {
    border-bottom: 1px solid #919da9;
  }
  .ant-table-cell-ellipsis {
    border-left: 1px solid #919da9;
  }
}

.custom-segment {
  border: 1px solid #919da9;
  background-color: transparent;
  padding: 0;
  .ant-segmented-group {
    border-radius: 5px !important;
    &:hover {
      background-color: #f4f8ff !important;
    }
    .ant-segmented-thumb {
      background-color: $primary !important;
      color: #919da9 !important;
    }
  }

  .ant-segmented-item {
    margin: 0;
    font-size: 12px;
    padding: 5px 20px !important;
    // background-color: #f4f8ff;
    color: #919da9 !important;
  }

  .ant-segmented-item-selected {
    background-color: $primary;
    color: white;

    &:hover {
      background: $primary !important;
    }
  }
}



.role-base-system {
  .ant-btn {
    &:not([disabled]) {
      background-color: $primary;

      &:hover {
        background-color: $primary;
      }
    }
  }
  // .

  .ant-input {
    border: none !important;
    padding: 0 15px !important;
  }
  .ant-pagination {
    padding: 10px;
    .ant-select {
      .ant-select-selector {
        padding: 0 10px !important;
      }
    }
  }
}

.custom-checkbox {
  .ant-checkbox-wrapper {
    .ant-checkbox-inner {
      background: transparent !important;
    }
  }
  .ant-checkbox-wrapper-checked {
    .ant-checkbox-inner {
      background: $primary !important;
    }
  }
}
.dispatch-modal {
  .ant-modal-content {
    padding: 10px 24px !important;
    padding-top: 15px !important;
  }
}

.cancel-btn {
  background-color: transparent;
  color: black !important;
  font-family: "Gilroy-Bold" !important;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 0px solid transparent !important;
  border: 1px solid #00205e !important;
  border-radius: 15px;
  box-shadow: none !important;
}
