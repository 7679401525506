#Dashboard-card {
  .ant-card-body {
    padding: 16px !important;
  }
}

// .gatepass-table {
//   .ant-table {
//     height: 240px;
//     max-height: 240px;
//     overflow: auto;
//   }
// }
.gatepass-table {
  td.ant-table-cell {
    padding: 5px 16px !important;
    font-size: 13px;
    color: $primary;
  }
  th.ant-table-cell {
    padding: 5px 16px !important;
    font-size: 13px;
    color: #919da9 !important;
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 15px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 15px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
.custom-dash-table {
  .ant-table {
    // min-height: 240px;
    min-height: 230px;

    td:last-child {
      border-bottom: none !important;
      padding-top: 25px;
    }
  }
}
