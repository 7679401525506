$breakpoint-large: 1024px;
$breakpoint-xl: 1280px;
$breakpoint-2xl: 1536px;

#lot-container {
  .lot-segment {
    padding: 0;
    border: 1px solid $border-color;
    background-color: #f3f7ff !important;
    .ant-segmented-item {
      border-radius: 15px;
      flex: 1;
      font-size: 12px;
      color: #919da9;

      &:hover {
        background-color: #f3f7ff !important;
        box-shadow: none;
      }
    }
  }
  .lot-select {
    .ant-select-selector {
      background-color: white !important;
      font-size: 12px;
      padding: 0 10px !important;
      color: #919da9;
    }
  }
  #reset-btn {
    outline: none;
    border: none;
    font-size: 12px;
    box-shadow: none;

    &:hover {
      color: white;
      border: none;
    }
  }
  .complete-buttom:hover {
    color: #00205e;
  }
}

// lot-side-section-card css
.lot-side-section-card {
  .ant-card-body {
    padding: 0;
  }
}
.lot-side-section-card:hover {
  box-shadow: none !important;
}

// add-lot-segment css
.add-lot-segment {
  background: white;
  border-radius: 15px !important;
  padding: 0;
  font-size: 16px;
  .ant-segmented-item {
    border: 1px solid #919da9;
    padding: 2px 0px;
    width: 50%;
    border-top-right-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;

    &:hover {
      background: white !important;
    }
    &:first-child {
      border-top-left-radius: 15px !important;
      border-bottom-left-radius: 15px !important;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    &:last-child {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      border-top-right-radius: 15px !important;
      border-bottom-right-radius: 15px !important;
    }
  }
  @media (min-width: $breakpoint-xl) {
    .ant-segmented-item {
      padding: 6px 0px !important;
    }
  }
  @media (min-width: $breakpoint-2xl) {
    .ant-segmented-item {
      padding: 9px 0px !important;
    }
  }

  .ant-segmented-item-selected {
    background-color: #e7efff;
    border-top-left-radius: 15px !important;
    border-bottom-left-radius: 15px !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    color: $primary;

    background-color: #e7efff;
    border: 1px solid $primary !important;

    &:hover {
      background-color: #e7efff !important;
    }

    &:first-child {
      border-top-left-radius: 15px !important;
      border-bottom-left-radius: 15px !important;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    &:last-child {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      border-top-right-radius: 15px !important;
      border-bottom-right-radius: 15px !important;
    }
  }
}

#Dark-Segment {
  border-radius: 15px;
  .ant-segmented-group {
    border: 1px solid rgba(145, 157, 169, 1);
    border-radius: 15px !important;
    .ant-segmented-item {
      margin: 0;
      padding: 0;
      box-shadow: none !important;
      border-radius: 14px !important; /* Inherit the border radius from the parent */
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;

      &:hover {
        background: transparent !important;
        border-radius: 14px;
      }

      .ant-segmented-item-label {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .greymoon,
      .greysun {
        display: block;
      }
      .bluemoon,
      .bluesun {
        display: none;
      }
    }

    .ant-segmented-item-selected {
      background-color: $primary !important;

      &:hover {
        background: $primary !important;
      }

      .ant-segmented-item-label {
        color: white;
      }

      &:first-child {
        border-top-left-radius: 14px !important;
        border-bottom-left-radius: 14px !important;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
      &:last-child {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        border-top-right-radius: 14px !important;
        border-bottom-right-radius: 14px !important;
      }

      .greymoon,
      .greysun {
        display: block;
      }
      .bluemoon,
      .bluesun {
        display: none;
      }
    }
  }
}

.side-lot-search {
  .ant-input {
    padding: 0px 15px !important;
  }
}

.custom-date-input {
  padding: 0rem 1rem !important;
  border: 1px solid $border-color !important;
  border-radius: 15px;

  .ant-input {
    border: none !important;
  }

  &:focus,
  &:hover,
  &:focus-visible {
    border-color: $border-color !important;
    box-shadow: none !important;
  }
  .ant-picker {
    height: 100%;
    padding: inherit;
  }
}

.lot-form {
  .ant-form-item-explain-error {
    margin: 0 0 !important;
  }
}

.graed {
  .ant-input {
    background-color: white !important;
    border-radius: 8px !important;
    padding: 10px !important;
    border-color: #b2bccf !important;
  }

  .ant-select {
    .ant-select-selector {
      background-color: white !important;
      border-radius: 8px !important;
      padding: 2.8px 8px !important;
      border-color: #b2bccf !important;

      .ant-select-selection-item {
        color: $primary;
      }
    }
  }

  .ant-form-item-label > label {
    color: #000 !important;
    font-size: 12px !important;
    width: 100%;
    font-family: "Gilroy-Regular";
  }
  .ant-form-item-label {
    padding: 0 0 2px 0 !important;
    height: 35px !important;
  }
}

.edit-bag-modal {
  min-width: 600px;
  .ant-modal-content {
    padding: 15px 0 !important;
  }
}
