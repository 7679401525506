.gatepass-container {
  #custom-pagination {
    font-size: 12px;
    .ant-pagination {
      display: flex;
      align-items: center !important;

      .ant-pagination-options {
        display: flex !important;
        align-items: center !important;

        .ant-select {
          .ant-select-selector {
            padding: 6px 15px !important;
            color: #919da9;
            border: 1px solid #919da9;
          }
          @media (max-width: 1500px) {
            .ant-select-selector {
              padding: 0px 8px !important;
              border-radius: 10px;
            }
          }
        }
      }
      .ant-pagination-item a {
        color: #919da9;
      }
      .ant-pagination-item-active {
        border-color: #ff5a26;
        background-color: transparent;
        color: #ff5a26;
        border-radius: 15px;
        padding: 20px 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        a {
          color: #ff5a26;
        }
      }
      @media (max-width: 1500px) {
        .ant-pagination-item-active {
          padding: 10px 8px; /* Adjust the padding values accordingly */
          border-radius: 10px;
          justify-content: center;
        }
      }
      .ant-pagination-item:not(.ant-pagination-item-active):hover {
        background-color: transparent;
      }
      .ant-pagination-prev:hover {
        background: transparent;
      }
      .ant-pagination-next button {
        color: #919da9;
      }
      .ant-pagination-prev button {
        color: #919da9;
      }
      .ant-pagination-options-quick-jumper {
        color: #919da9;
        height: auto;
        display: flex;
        align-items: center;
        input {
          background-color: transparent;
          height: 43px;
          color: #919da9;
          border-color: #919da9;
          border-radius: 15px;
        }
        @media (max-width: 1500px) {
          input {
            border-radius: 10px;
            height: 32px;
            width: 35px;
          }
        }
      }
      @media (max-width: 1500px) {
        .ant-pagination-options-quick-jumper {
          padding: 0;
          text-wrap: nowrap;
        }
        .ant-pagination-options {
          margin-inline-start: 0px;
        }
      }
      .ant-pagination-jump-prev
        .ant-pagination-item-container
        .ant-pagination-item-link-icon {
        color: #ff5a26;
      }
      .ant-pagination-jump-next
        .ant-pagination-item-container
        .ant-pagination-item-link-icon {
        color: #ff5a26;
      }
    }
  }
  .ant-table {
    position: relative;
    // background-color: white !important;
    background-color: #f4f8ff !important;

    .ant-table-thead {
      tr {
        th {
          color: #919da9 !important;
          background: #f4f8ff;
          font-size: 12px !important;
          font-weight: 400 !important;
        }
      }
    }

    .ant-table-tbody {
      tr {
        th,
        th:hover {
          background-color: #f4f8ff !important;
        }
        td {
          background-color: #f4f8ff !important;
          color: $primary;
          font-size: 14px;
          font-weight: 400;

          @media (max-width: 1536px) {
            font-size: 12px !important;
          }
        }
      }

      .selected-row {
        cursor: pointer;
        // background-color: white;
        position: relative;

        > td:first-child {
          border-left: 5px solid $primary;
        }
      }
    }

    .ant-table-cell:hover {
      background-color: #f4f8ff !important;
    }
    .ant-table-cell-fix-right {
      background-color: #f4f8ff !important;
    }
  }

  .Delete-btn {
    outline: none !important;
    box-shadow: none !important;
    &:hover,
    &:focus {
      border-color: $secondary !important;
    }
  }

  #gatepass-description {
    .ant-descriptions-item {
      padding-bottom: 2px !important;
    }
    .ant-descriptions-item-label {
      color: #919da9;
      font-size: 12px;
    }

    .ant-descriptions-item-content {
      color: $primary;
      padding-bottom: 15px;
      font-size: 14px;
    }
  }
  .country-input {
    .ant-input-number-group-addon {
      border: none !important;
    }
  }
}
