.companycard {
  .ant-card-body {
    padding: 10px 20px;
  }
}
.custom-checkbox {
  border-radius: 15px;
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background: $primary !important;
    }
  }

  .ant-checkbox-inner {
    
    border: 1px solid $border-color !important;
  }
}
.custom-checkbox:hover .ant-checkbox-inner {
  border: 1px solid $border-color !important;
}
