$breakpoint-large: 1024px;
$breakpoint-xl: 1280px;
$breakpoint-2xl: 1536px;

.ant-btn {
  :focus,
  :focus-visible {
    box-shadow: none !important;
    outline: none !important;
  }
}

.color-scroll {
  ::-webkit-scrollbar {
    width: 10px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 15px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 15px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.filled-btn {
  background-color: $primary;
  color: white !important;
  padding: 8px 15px;
  height: auto;
  font-family: "Gilroy-Bold" !important;
  display: flex;
  align-items: center;
  justify-content: center;
  // min-width: 124px !important;
  outline: 0px solid transparent !important;
  border: 1px solid $primary !important;
  border-radius: 15px;
  box-shadow: none !important;

  &:hover,
  &:focus,
  &:focus-visible {
    color: white !important;
    outline: 0px solid transparent !important;
    border: 1px solid $primary;
    box-shadow: none !important;
  }

  &:disabled {
    background-color: $primary !important;
  }
}
.upload-btn {
  background-color: $primary;
  color: white;
  padding: 16px 30px;
  height: auto;
  font-family: "Gilroy-Bold" !important;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 0px solid transparent !important;
  border: 1px solid $primary !important;
  border-radius: 15px;
  box-shadow: none !important;

  &:hover,
  &:focus,
  &:focus-visible {
    color: white !important;
    outline: 0px solid transparent !important;
    border: 1px solid $primary;
    box-shadow: none !important;
  }
}

.ant-btn-lg {
  padding: 5px 15px;
}
.ant-modal-footer {
  .ant-btn {
    color: black;
    background-color: transparent;
    border: 1px solid #919da9;

    &:hover {
      background-color: transparent !important;
      border: 1px solid #919da9 !important;
      color: black !important;
    }
  }
}

.outline-btn {
  background-color: transparent;
  // min-width: 124px !important;
  padding: 8px 15px;
  height: auto;
  font-family: "Gilroy-Bold" !important;
  color: $primary;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none !important;
  outline: 0px solid transparent !important;
  border: 1px solid $primary !important;

  &:hover,
  &:focus,
  &:focus-visible {
    color: $primary !important;
    outline: 0px solid transparent !important;
    border: 1px solid $primary;
    box-shadow: none !important;
  }
}

.warning-btn {
  background-color: transparent;
  padding: 8px 15px;
  height: auto;
  font-family: "Gilroy-Bold" !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $secondary;
  border-radius: 15px;
  box-shadow: none !important;
  outline: 0px solid transparent !important;
  border: 1px solid $secondary !important;

  &:hover,
  &:focus,
  &:focus-visible {
    color: $secondary !important;
    outline: none !important;
    border: 1px solid $secondary;
    box-shadow: none !important;
  }
}

.action-icon {
  font-size: 16px;
  color: #919da9;
  margin-right: 10px;
}
.print-icon {
  font-size: 240px;
  color: #919da9;
  margin-right: 20px;
}
.ant-descriptions .ant-descriptions-item-label::after {
  content: "";
}
.ant-avatar {
  border-radius: 15px !important;
  .ant-avatar-string {
    font-size: 50px;
    font-weight: 400;
    font-family: Gilroy-Bold;
  }
}

.ant-popconfirm {
  .ant-popconfirm-buttons {
    .ant-btn {
      color: black !important;
      border: 1px solid #919da9;

      &:hover {
        border: 1px solid #919da9;
        background-color: transparent;
      }
    }
  }
}

.ant-form-vertical .ant-form-item-label > label {
  color: #919da9;
  font-size: 16px;
  font-weight: 400;
}

/* .ant-btn-primary {
  background-color: $primary !important;

  &:hover {
    background-color: $primary;
  }
} */

/* .ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  visibility: hidden;
  display: none;
} */

/* .ant-form-vertical .ant-form-item-label > label::after {
  display: inline-block;
  margin-inline-end: 4px;
  margin-left: 3px;
  color: red;
  font-size: 16px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
  visibility: visible;
} 

// form value color

.ant-input-affix-wrapper > input.ant-input::placeholder {
  font-size: 16px !important;
}
/* .ant-input-number .ant-input-number-input:placeholder-shown {
  font-size: 16px !important;
} */
.ant-input-group .ant-input-group-addon {
  border: 0;
}

.ant-input-outlined {
  &:hover {
    background-color: transparent !important;
  }
  &:focus {
    background-color: transparent !important;
  }
}
.ant-input-status-error {
  background-color: transparent !important;
  &:hover {
    background-color: transparent !important;
  }
  &:focus {
    background-color: transparent !important;
  }
}

// custom-search for all screen
.custom-search {
  border: 0 !important;
  background-color: transparent !important;
  border-radius: 15px;
  padding: 4px 11px 4px 0px;
  border: 1px solid #919da9 !important;

  &:focus-within {
    box-shadow: none !important;
  }

  .ant-input {
    border: 0 !important;
    padding: 0.3rem 5px !important;
  }

  /* 
  @media (min-width: $breakpoint-2xl) {
    .ant-input {
      padding: 0.9rem 1rem !important;
    }
  }
  @media (min-width: $breakpoint-xl) {
    .ant-input {
      padding: 0.7rem 1rem !important;
    }
  } */
}

@media (min-width: 1600px) {
  .custom-search {
    .ant-input {
      padding: 11px 1rem !important;
    }
  }
}

// search datepicker css

.search-datepicker {
  .ant-picker-input {
    padding: 0.3rem 0.1rem !important;
    input {
      color: #919da9 !important;
      font-family: Gilroy-Medium;
    }
  }
  @media (min-width: $breakpoint-2xl) {
    .ant-picker-input {
      padding: 0.9rem 1rem !important;
    }
  }
  @media (min-width: $breakpoint-xl) {
    .ant-picker-input {
      padding: 0.6rem 0rem !important;
    }
  }
}

.custom-input-group {
  border: 1px solid #919da9;
  border-radius: 15px;
  background-color: transparent !important;
  .ant-input {
    border: none !important;
  }
  .ant-input-group-addon {
    border-right: 1px solid #919da9 !important;
    border-radius: 15px !important;
    background-color: #e9e9ed !important;
  }
}

.custom-input-group {
  border: 1px solid #919da9;
  border-radius: 15px;
  background-color: transparent !important;
  .ant-input {
    border: none !important;
  }
  .ant-input-group-addon {
    border-right: 1px solid #919da9 !important;
    border-radius: 15px !important;
    background-color: #e9e9ed !important;
  }
  // .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select{
  //   height: 25px;
  //   width: 25px;
  //   margin-bottom: 0;
  //   margin-inline-end: 0;
  // }
  .ant-upload-list-item-name {
    display: none;
  }
  .ant-upload-wrapper
    .ant-upload-list.ant-upload-list-picture
    .ant-upload-list-item-error {
    border: none;
  }
  .ant-upload-list-item-actions .picture {
    display: none;
  }
}

// upload buttons css
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload.ant-upload-select {
  border-color: #919da9;
  background: transparent;
  border-radius: 15px;
}
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload.ant-upload-select:not(.ant-upload-disabled):hover {
  border-color: $primary;
}

// chackbox design
.ant-checkbox-inner {
  border: 1px solid #00205e !important;
  background-color: #f3f7ff;
  border-radius: 3px;
}
.ant-checkbox-wrapper {
  color: #919da9;
}
.ant-form label {
  font-size: 16px;
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-inner,
:where(.css-dev-only-do-not-override-ccytmk).ant-checkbox:not(
    .ant-checkbox-disabled
  ):hover
  .ant-checkbox-inner {
  border-color: #00205e;
}
// .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
//   .ant-checkbox-checked:not(.ant-checkbox-disabled)
//   .ant-checkbox-inner {
//     background-color: #00205e !important;
// }

#hover-white:focus {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}

#hover-white:hover:not(.text-color-white) {
  color: black !important;
  outline: none !important  ;
}

.text-color-white:hover {
  color: white !important;
}

/* #hover-white:hover {
  color: black !important;
} */

// collaps css
.ant-collapse {
  border: 1px solid #b2bccf;
  background-color: #f4f5f8;
  .ant-collapse-item {
    border-bottom: none;
    .ant-collapse-header {
      display: flex;
      align-items: center;
    }
  }
  .ant-collapse-content {
    border-top: 1px solid #b2bccf;
  }
}

.dispatch-select {
  width: 200px !important;
  .ant-select-selector {
    min-width: 200px !important;

    .ant-select-selection-placeholder {
      text-align: left;
    }
    .ant-select-selection-item {
      text-align: left;
    }
  }
}
.table-scrollbar {
  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 15px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 15px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
