#login-container {
  position: relative;
  background: url("../img/dashboard/login-bg.png") no-repeat center;
  background-size: cover !important;

  .ant-btn {
    min-width: 115px;
    background-color: #0e457e;
    transition: all 0.4s ease-in-out;
    padding: 25px 10px !important;
    display: flex;
    justify-content: center;
    color: white;
    align-items: center;
    outline: none;
    border-radius: 15px;
    text-transform: uppercase;
    border: none;

    :hover {
      color: white;
      box-shadow: none;
      border: none;
    }
  }
}

.dashboard-segment {
  border-radius: 5px !important;
  .ant-segmented-item-selected {
    background-color: $primary;
    color: #919da9 !important;

    &:hover {
      background: $primary !important;
    }
  }
  .ant-segmented-thumb {
    background-color: $primary !important;
    color: #919da9 !important;
  }
}

// @media (min-width: 768px) {
#login-container::before,
#login-container::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%; /* Adjust as needed */
  height: 232px; /* Adjust as needed */
  background: url("../img/dashboard/sand-layer.png") no-repeat center; /* Path to your first bottom image */
  background-repeat: no-repeat;
  background-size: cover;
  // }
}

@media (min-width: 768px) and (max-width: 1536px) {
  .zindex {
    z-index: 2 !important;
  }
}

@media (max-width: 1250px) {
  .login-card {
    width: 45% !important;
  }
}
@media (max-width: 992px) {
  .login-card {
    width: 65% !important;
  }
}
.login-card {
  width: 35%;
}

@media (max-width: $small-device-width) {
  .login-card {
    width: 85% !important;
  }

  #login-container::before,
  #login-container::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 200px !important;
    background: url("../img/dashboard/sand-layer.png") no-repeat center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

// new login page css
#login-container-new {
  // position: relative;
  background: url("../img/dashboard/pattern.svg") no-repeat center;
  background-size: cover !important;
  text-align: center;
  .ant-btn {
    width: 100%;
    background-color: #0e457e;
    transition: all 0.4s ease-in-out;
    padding: 25px 10px !important;
    display: flex;
    justify-content: center;
    color: white;
    align-items: center;
    outline: none;
    border-radius: 15px;
    text-transform: uppercase;
    border: none;

    :hover {
      color: white;
      box-shadow: none;
      border: none;
    }
  }
}
.login-input {
  border: 1px solid #919da9;
  border-radius: 15px;
  .ant-input {
    border: none !important;
    padding-left: 8px !important;
  }
}
.ant-input-affix-wrapper-status-error:not(
    .ant-input-affix-wrapper-disabled
  ):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
  border-color: #919da9 !important;
  box-shadow: none !important;
}
.ant-input-affix-wrapper:hover {
  border-color: #919da9;
}
.ant-input-affix-wrapper:focus {
  border-color: #919da9 !important;
  box-shadow: none !important;
}
.ant-input-affix-wrapper:focus-within {
  border-color: #919da9;
  box-shadow: none;
}
